// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import shop from './shop';
import toast from './toast';
import language from './language';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({ menu, shop, toast, language });

export default reducers;
