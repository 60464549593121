import { Slide, Snackbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { closeToast } from 'store/reducers/toast';

const snackbarStyling = {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    width: '35vw'
};

const SlideTransition = (props) => {
    return <Slide {...props} direction="down" />;
};

const CommonToast = () => {
    const dispatch = useDispatch();
    const { isVisible, message } = useSelector((state) => state.toast);

    return (
        <>
            <Snackbar
                open={isVisible}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => dispatch(closeToast())}
                TransitionComponent={SlideTransition}
                message={message}
                ContentProps={{ sx: snackbarStyling }}
            />
        </>
    );
};

export default CommonToast;
