import { useRef, useState } from 'react';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Box, ButtonBase, ClickAwayListener, MenuItem, Paper, Popper, Select, Stack, Typography } from '@mui/material';

import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';

import { useLang } from 'hooks/common/lang';
import { LANGUAGE_FLAGS, LANGUAGE_OPTIONS } from 'constants/index';

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const FlagImage = styled('img')(() => ({
    borderRadius: '50%',
    width: '32px',
    height: '32px',
    objectFit: 'cover'
}));

const StyledMenuItem = styled(MenuItem)(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
}));

const Language = () => {
    const theme = useTheme();
    const { lang, setLang, $t } = useLang();

    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleClick = (lang) => {
        setLang(lang);
        setOpen(false);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 1.5 }}>
            <ButtonBase
                sx={{
                    p: 0.25,
                    bgcolor: open ? 'grey.300' : 'transparent',
                    borderRadius: 1,
                    '&:hover': { bgcolor: 'secondary.lighter' }
                }}
                aria-label="open language"
                ref={anchorRef}
                aria-controls={open ? 'language-grow' : undefined}
                aria-haspopup="true"
                onClick={() => setOpen((prevOpen) => !prevOpen)}
            >
                <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
                    <FlagImage src={LANGUAGE_FLAGS[lang]} alt={lang} />
                </Stack>
            </ButtonBase>
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 9]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions type="fade" in={open} {...TransitionProps}>
                        {open && (
                            <Paper
                                sx={{
                                    boxShadow: theme.customShadows.z1,
                                    width: 290,
                                    minWidth: 240,
                                    maxWidth: 290,
                                    [theme.breakpoints.down('md')]: {
                                        maxWidth: 250
                                    }
                                }}
                            >
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MainCard elevation={0} border={false} content={false}>
                                        {LANGUAGE_OPTIONS.map((option) => {
                                            return (
                                                <StyledMenuItem key={option.value} onClick={() => handleClick(option.value)}>
                                                    <img src={LANGUAGE_FLAGS[option.value]} alt="flag" width={32} height={16} />
                                                    <span>{$t(option.label)}</span>
                                                </StyledMenuItem>
                                            );
                                        })}
                                    </MainCard>
                                </ClickAwayListener>
                            </Paper>
                        )}
                    </Transitions>
                )}
            </Popper>
        </Box>
    );
};

export default Language;
