import { createRoot } from 'react-dom/client';
import {
    RouterProvider,
    createBrowserRouter,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import React from 'react';

// scroll bar
import 'simplebar/src/simplebar.css';

// third-party
import { Provider as ReduxProvider } from 'react-redux';

// apex-chart
import './assets/third-party/apex-chart.css';

// project import
import App from './App';
import './index.css';
import { store } from 'store';
import reportWebVitals from './reportWebVitals';

import ErrorPage from 'pages/errors/ErrorPage';

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //
Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,

    integrations: [
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
        }),
        Sentry.replayIntegration({
            maskAllText: false
        })
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 1.0,
    replaysOnErrorSampleRate: 1.0
});

const router = createBrowserRouter([{ path: '*', element: <App />, errorElement: <ErrorPage /> }], { basename: '/' });

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <ReduxProvider store={store}>
        <RouterProvider router={router} />
    </ReduxProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
