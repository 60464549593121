import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Toolbar, useMediaQuery } from '@mui/material';

// project import
import Drawer from './Drawer';
import Header from './Header';
import getNavigation from 'menu-items';
import Breadcrumbs from 'components/@extended/Breadcrumbs';

// types
import { openDrawer } from 'store/reducers/menu';
import { useShopIdHook } from 'hooks/common/shopId';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));
    const dispatch = useDispatch();
    const { getShop } = useShopIdHook();

    const { drawerOpen } = useSelector((state) => state.menu);

    const shop = getShop();
    let isVerified = shop?.isVerified ?? false;

    // drawer toggler
    const [open, setOpen] = useState(drawerOpen);
    const handleDrawerToggle = () => {
        setOpen(!open);
        dispatch(openDrawer({ drawerOpen: !open }));
    };

    // set media wise responsive drawer
    useEffect(() => {
        setOpen(!matchDownLG);
        dispatch(openDrawer({ drawerOpen: !matchDownLG }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownLG]);

    useEffect(() => {
        if (open !== drawerOpen) setOpen(drawerOpen);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawerOpen]);

    return (
        <Box sx={{ display: 'flex', width: '100%' }}>
            <Header open={open} handleDrawerToggle={handleDrawerToggle} />
            <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
            <Box
                component="main"
                className={`opaper-main-container ${drawerOpen ? 'opaper-drawer-open' : ''}`}
                sx={{
                    width: '100%',
                    maxWidth: '100%',
                    transition: 'max-width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
                    flexGrow: 1,
                    p: { xs: 2, sm: 2 }
                }}
            >
                <Toolbar />
                <Breadcrumbs navigation={getNavigation({ isVerified })} title titleBottom card={false} divider={false} />
                <div style={{ paddingBottom: '64px' }}>
                    <Outlet />
                </div>
            </Box>
        </Box>
    );
};

export default MainLayout;
