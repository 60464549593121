import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setLanguage } from 'store/reducers/language';

export const useLang = () => {
    const dispatch = useDispatch();
    const { lang, $t: t } = useSelector((state) => state.language);

    const setLang = (lang) => {
        dispatch(setLanguage(lang));
    };

    const $t = useCallback(
        (key = '') => {
            const [category, langKey] = key.split('.');

            const result = t[lang]?.[category]?.[langKey];

            return result || key;
        },
        [t, lang]
    );

    return { lang, $t, setLang };
};
