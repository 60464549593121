// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    isVisible: false,
    message: ''
};

const toast = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        openToast(state, action) {
            state.isVisible = true;
            state.message = action.payload;
        },
        closeToast(state) {
            state.isVisible = false;
            state.message = '';
        }
    }
});

export default toast.reducer;

export const { openToast, closeToast } = toast.actions;
