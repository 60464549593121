// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    selected: [],
    list: [],
    selectedName: '',
    listName: [],
    user: '',
    phoneNumber: ''
};

// ==============================|| SLICE - MENU ||============================== //

const shop = createSlice({
    name: 'shop',
    initialState,
    reducers: {
        setSelectedShop(state, action) {
            state.selected = action.payload.selected;
        },

        setListShop(state, action) {
            state.list = action.payload.list;
        },

        setSelectedShopName(state, action) {
            state.selectedName = action.payload.selectedName;
        },

        setListShopName(state, action) {
            state.listName = action.payload.listName;
        },

        setUser(state, action) {
            state.user = action.payload.user;
        },

        setPhoneNumber(state, action) {
            state.phoneNumber = action.payload.phoneNumber;
        },

        setInitialShopState() {
            return initialState;
        }
    }
});

export default shop.reducer;

export const { setSelectedShop, setListShop, setSelectedShopName, setListShopName, setUser, setPhoneNumber, setInitialShopState } =
    shop.actions;
