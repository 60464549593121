// project import
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import CommonToast from 'components/commons/Toast';
// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => (
    <ThemeCustomization>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CommonToast />
            <ScrollTop>
                <Routes />
            </ScrollTop>
        </LocalizationProvider>
    </ThemeCustomization>
);

export default App;
