// types
import { createSlice } from '@reduxjs/toolkit';
import { LS_LANG } from 'constants/index';

import ID from 'lang/id.json';
import EN from 'lang/en.json';

// initial state
const initialState = {
    lang: localStorage.getItem(LS_LANG) ?? 'ID',
    $t: {
        ID,
        EN
    }
};

// ==============================|| SLICE - MENU ||============================== //

const language = createSlice({
    name: 'language',
    initialState,
    reducers: {
        setLang(state, action) {
            state.lang = action.payload;
            localStorage.setItem(LS_LANG, action.payload);
        }
    }
});

export default language.reducer;

export const { setLang: setLanguage } = language.actions;
