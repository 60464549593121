import { useRoutes } from 'react-router-dom';

// project import
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import ErrorPage from 'pages/errors/ErrorPage';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([
        MainRoutes,
        LoginRoutes,
        {
            path: '*',
            element: <ErrorPage message="Halaman tidak ditemukan." />
        }
    ]);
}
