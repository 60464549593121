import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export const useShopIdHook = () => {
    const shop = useSelector((state) => state.shop);

    const getShop = useCallback(() => {
        const selectedShops = shop.list.filter((s) => shop.selectedName.indexOf(s.name) !== -1);
        return selectedShops[0];
    }, [shop.list, shop.selectedName]);

    const getShopId = useCallback(() => {
        const selectedShops = shop.list.filter((s) => shop.selectedName.indexOf(s.name) !== -1);
        const selectedShopIds = selectedShops.map((s) => s.id);
        return selectedShopIds[0];
    }, [shop.list, shop.selectedName]);

    const getShopIds = useCallback(() => {
        const selectedShops = shop.list.filter((s) => shop.selectedName.indexOf(s.name) !== -1);
        const selectedShopIds = selectedShops.map((s) => s.id);
        return selectedShopIds;
    }, [shop.list, shop.selectedName]);

    return { getShopId, getShopIds, getShop, shop };
};
