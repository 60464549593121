import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import ThemeCustomization from 'themes';

import chickenCryImg from 'assets/images/icons/chicken-cry.png';

/* eslint-disable react/prop-types */
const ErrorPage = ({ message = 'Mohon maaf terjadi kesalahan.' }) => {
    const navigate = useNavigate();

    return (
        <ThemeCustomization>
            <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center', justifyContent: 'center', height: '90vh' }}>
                <img src={chickenCryImg} alt="Not Found Page" />
                <p>{message}</p>
                <Button onClick={() => navigate('/')} sx={{ textTransform: 'none' }}>
                    Kembali ke Menu Utama
                </Button>
                <p>atau</p>
                <Button onClick={() => navigate('/login')}>Login Ulang</Button>
            </div>
        </ThemeCustomization>
    );
};

export default ErrorPage;
