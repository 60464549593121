import { useEffect, useState } from 'react';
import { getShopList, getUserData, postGetOTP, postValidateOTP } from 'services/auth';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LS_AUTH, LS_USER } from '../constants';
import { LS_PHONE_NUMBER, LS_SHOP, LS_USERNAME, LS_SELECTED_SHOP_NAME } from 'constants/index';
import { setListShop, setListShopName, setPhoneNumber, setSelectedShop, setSelectedShopName, setUser } from 'store/reducers/shop';

export const useCommonHooks = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const shop = useSelector((state) => state.shop);

    const handleChangeSelectedShops = (value) => {
        if (value.length > 0) {
            const selected = shop.list.filter((s) => s.name === value[0]);
            localStorage.setItem(LS_SELECTED_SHOP_NAME, JSON.stringify(value));
            dispatch(setSelectedShopName({ selectedName: value }));
            window.Intercom('update', {
                name: value,
                shop_id: selected[0].id
            });
        }
    };

    const initiateShopInfo = () => {
        const shops = JSON.parse(localStorage.getItem(LS_SHOP));
        const user = localStorage.getItem(LS_USERNAME);
        const phoneNumber = localStorage.getItem(LS_PHONE_NUMBER);
        const selectedShopName = JSON.parse(localStorage.getItem(LS_SELECTED_SHOP_NAME) ?? '[]');
        const userId = localStorage.getItem(LS_USER);

        let list = [];
        let listName = [];
        (shops ?? []).forEach((shop) => {
            list.push({ ...shop });
            listName.push(shop.name);
        });

        initiateIntercom(shops[0].name, userId, shops[0].id);

        dispatch(setListShop({ list }));
        dispatch(setSelectedShop({ selected: list[0] }));
        dispatch(
            setSelectedShopName({ selectedName: selectedShopName.length > 0 ? selectedShopName : list[0]?.name ? [list?.[0].name] : [] })
        );
        dispatch(setListShopName({ listName }));
        dispatch(setUser({ user }));
        dispatch(setPhoneNumber({ phoneNumber }));
    };

    const initiateIntercom = (selectedShopName, userId, shopId) => {
        window.intercomSettings = {
            app_id: 'awo9zx4l',
            horizontal_padding: 20,
            vertical_padding: 20,
            name: selectedShopName,
            email: selectedShopName,
            userId: userId,
            shop_id: shopId
        };
        window.Intercom('boot', {
            app_id: 'awo9zx4l',
            name: selectedShopName
        });
    };

    useEffect(() => {
        if (localStorage.getItem(LS_AUTH)) {
            setTimeout(() => {
                initiateShopInfo();
            }, 1000);
        } else {
            navigate('/login');
        }
    }, []);

    return {
        shop,
        handleChangeSelectedShops,
        initiateShopInfo
    };
};
