// material-ui
import { Box, Typography } from '@mui/material';

// project import
import NavGroup from './NavGroup';
import getMenuItems from 'menu-items';

import { useShopIdHook } from 'hooks/common/shopId';
import { useLang } from 'hooks/common/lang';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
    const { getShop } = useShopIdHook();
    const { $t } = useLang();

    const shop = getShop();
    let isVerified = shop?.isVerified ?? false;

    const navGroups = getMenuItems({ isVerified, $t }).items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Fix - Navigation Group
                    </Typography>
                );
        }
    });

    return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
