import { useEffect, useState } from 'react';
import { getShopList, getUserData, postGetOTP, postValidateOTP } from 'services/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LS_AUTH, LS_USER } from '../constants';
import { LS_PHONE_NUMBER, LS_SELECTED_SHOP_NAME, LS_SHOP, LS_USERNAME } from 'constants/index';
import { setInitialShopState } from 'store/reducers/shop';
import { useCommonHooks } from './common';
import { useLang } from './common/lang';

export const useLoginHooks = () => {
    const { $t } = useLang();

    const [otp, setOtp] = useState();
    const [phone, setPhone] = useState();
    const [token, setToken] = useState();
    const [errOtp, setErrOtp] = useState();
    const [isRememberLogin, setIsRememberLogin] = useState(false);
    const [isLSCleared, setIsLSCleared] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { initiateShopInfo } = useCommonHooks();

    const handleSendOtpToUser = async (phoneInput, method) => {
        const payload = {
            phone_number: `+62${phoneInput}`,
            message_type: method
        };

        const response = await postGetOTP(payload);

        if (response.code === 200) {
            const { token, user_id } = response.data;

            setPhone(phoneInput);
            setToken(token);

            localStorage.setItem(LS_USER, user_id);

            return null;
        } else {
            return response.err_msg;
        }
    };

    const handleValidateOtp = async (finalOtp) => {
        const payload = {
            otp_code: parseInt(finalOtp),
            phone_number: `62${phone}`,
            token,
            remember_me: isRememberLogin
        };

        const response = await postValidateOTP(payload);

        if (response?.code === 200) {
            localStorage.setItem(LS_AUTH, response.data.auth_token);
            navigate(`/sales`);

            fetchUserShopList();
            fetchUserData();
        } else {
            if (response.err_msg.toLowerCase() === 'error unauthorized') {
                setErrOtp($t('auth.OpErrorInvalidOtp'));
            } else if (response.err_msg.toLowerCase().startsWith('otp max request')) {
                setErrOtp($t('auth.OpErrorNoAttemptOtp'));
            } else {
                setErrOtp(response.err_msg);
            }
        }
    };

    const fetchUserShopList = async (isCreateNewShop) => {
        const response = await getShopList();

        if (response?.shops) {
            let list = [];
            let listName = [];
            response.shops.forEach((shop) => {
                list.push({ id: shop.id, name: shop.name, isVerified: shop.is_verified });
                listName.push(shop.name);
            });

            localStorage.setItem(LS_SHOP, JSON.stringify(list));

            if (isCreateNewShop) {
                initiateShopInfo();
            }
            // dispatch(setListShop({ list }));
            // dispatch(setSelectedShop({ selected: list[0] }));
            // dispatch(setSelectedShopName({ selectedName: list[0].name }));
            // dispatch(setListShopName({ listName }));
        } else {
            // todo: handle error
        }
    };

    const fetchUserData = async () => {
        const response = await getUserData();

        if (response) {
            localStorage.setItem(LS_USERNAME, response.username ?? '');
            localStorage.setItem(LS_PHONE_NUMBER, response.phone_number);
        } else {
            // todo: handle error
        }
    };

    const logOut = () => {
        clearLS();
        dispatch(setInitialShopState());
        navigate(`/login`);
    };

    const handleNavigateRegister = () => {
        navigate('/register');
    };

    const clearLS = () => {
        localStorage.removeItem(LS_AUTH);
        localStorage.removeItem(LS_SHOP);
        localStorage.removeItem(LS_USERNAME);
        localStorage.removeItem(LS_PHONE_NUMBER);
        localStorage.removeItem(LS_USER);
        localStorage.removeItem(LS_SELECTED_SHOP_NAME);
    };

    useEffect(() => {
        if (location.pathname === '/login') {
            clearLS();
        }
    }, []);

    return {
        otp,
        setOtp,
        handleSendOtpToUser,
        handleValidateOtp,
        logOut,
        errOtp,
        fetchUserShopList,
        handleNavigateRegister,
        isRememberLogin,
        setIsRememberLogin
    };
};
