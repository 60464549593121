// assets
import { DollarOutlined } from '@ant-design/icons';
import CommonMenuIcon from 'components/commons/MenuIcon';
import SalesSummaryIcon from 'assets/images/icons/menuSVG/salesSummary.svg';
import SalesProductIcon from 'assets/images/icons/menuSVG/salesProduct.svg';
import CategoryProductIcon from 'assets/images/icons/menuSVG/categoryProduct.svg';
import DownloadReportIcon from 'assets/images/icons/menuSVG/download-report.svg';
import EmailReportIcon from 'assets/images/icons/menuSVG/email-report.svg';
import OrderHistoryIcon from 'assets/images/icons/menuSVG/order-history.svg';
import InventoryFlowIcon from 'assets/images/icons/menuSVG/inventory-flow-report.svg';

// icons
const icons = {
    DollarOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const getReportMenu = ({ $t }) => {
    return {
        id: 'group-dashboard',
        title: $t('sidebar.OpMenuGroupReport'),
        type: 'group',
        children: [
            {
                id: 'dashboard',
                title: $t('report_summary.OpTitle'),
                type: 'item',
                url: '/sales',
                icon: CommonMenuIcon(SalesSummaryIcon),
                breadcrumbs: false
            },
            {
                id: 'product-sales',
                title: $t('product_report.OpTitle'),
                type: 'item',
                url: '/product-sales',
                icon: CommonMenuIcon(SalesProductIcon),
                breadcrumbs: false
            },
            {
                id: 'category-sales',
                title: $t('category_report.OpTitle'),
                type: 'item',
                url: '/category-sales',
                icon: CommonMenuIcon(CategoryProductIcon),
                breadcrumbs: false
            },
            {
                id: 'inventory-flow-report',
                title: $t('inventory_flow.OpTitle'),
                type: 'item',
                url: '/inventory-flow-report',
                icon: CommonMenuIcon(InventoryFlowIcon),
                breadcrumbs: false
            },
            {
                id: 'omset',
                title: $t('omset.OpTitle'),
                type: 'item',
                url: '/omset',
                icon: icons.DollarOutlined,
                breadcrumbs: false
            },
            {
                id: 'email-report',
                title: $t('email_report.OpTitle'),
                type: 'item',
                url: '/email-report',
                icon: CommonMenuIcon(EmailReportIcon),
                breadcrumbs: false
            },
            {
                id: 'download-report',
                title: $t('download_report.OpTitle'),
                type: 'item',
                url: '/download-report',
                icon: CommonMenuIcon(DownloadReportIcon),
                breadcrumbs: false
            },
            {
                id: 'order-history',
                title: $t('order_history.OpTitle'),
                type: 'item',
                url: '/order-history',
                icon: CommonMenuIcon(OrderHistoryIcon),
                breadcrumbs: false
            }
        ]
    };
};

export default getReportMenu;
